<template>
  <div>
    <CrudRead v-bind="$store.state.stone_model.shop_product_campaign"></CrudRead>
    <WsIndexBelongsToMany
      :belongsToManyModelName="$store.state.stone_model.shop_product.modelName"
      :belongsToManyFields="$store.state.stone_model.shop_product.fields"
      :belongsToManyShowFields="belongsToMany.showFields"
      :belongsToManyPickShowFields="belongsToMany.pickShowFields"
      :belongsToManyPickFilterSelects="_belongsToManyPickFilterSelects"
      :modelName="$store.state.stone_model.shop_product_campaign.modelName"
      :id="$route.params.id"
      updateBtnLabel="管理商品"
    ></WsIndexBelongsToMany>
  </div>
</template>

<script>
export default {
  data() {
    return {
      belongsToMany: {
        showFields: ["no", "name", "spec"],
        pickShowFields: ["no", "name", "spec", "is_active", "price"],
      },
    };
  },
  computed: {
    _belongsToManyPickFilterSelects() {
      const _belongsToManyPickFilterSelects = {};
      _belongsToManyPickFilterSelects.featured_classes =
        this.$store.state.stone_model.shop_product.crudSetting.index.filterSelects.featured_classes;
      _belongsToManyPickFilterSelects.shop_classes =
        this.$store.state.stone_model.shop_product.crudSetting.index.filterSelects.shop_classes;
      _belongsToManyPickFilterSelects.shop_subclasses =
        this.$store.state.stone_model.shop_product.crudSetting.index.filterSelects.shop_subclasses;
      _belongsToManyPickFilterSelects.is_active =
        this.$store.state.stone_model.shop_product.crudSetting.index.filterSelects.is_active;
      return _belongsToManyPickFilterSelects;
    },
  },
};
</script>